export const useStorageStore = defineStore("storageStore", {
  state() {
    return {
      storage: {},
    };
  },
  actions: {
    addStorageState(storageState) {
      this.storage = Object.assign(this.storage, storageState);
    },

    removeStorageState(key) {
      delete this.storage[key];
    },
  },
  persist: {},
});
