<template>
  <div class="footer-contacts">
    <h4 class="footer-contacts__title">
      {{ _T("@Contacts") }}
    </h4>
    <FooterContactBlock :contact="hotline" />
    <div class="footer-contacts__block">
      <MyLink name="contacts" :hash="'#shops'" class="footer-contacts__link">
        <span class="footer-contacts__link-text underline">
          {{ _T("@Our stores") }}
        </span>
      </MyLink>
    </div>
    <FooterContactBlock
      v-for="(contact, index) in contacts"
      :key="index"
      :contact="contact"
    />
    <div class="footer-contacts__block">
      <a class="footer-contacts__link" href="mailto:support@greenvision.com.ua">
        <svg
          class="footer-contacts__link-img"
          width="29"
          height="18"
          viewBox="0 0 29 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.9062 8.99865L29.0014 16.884V1.11328L17.9062 8.99865Z"
            fill="white"
          />
          <path
            d="M11.0951 8.99775L0 1.11133V16.8831L11.0951 8.99775Z"
            fill="white"
          />
          <path
            d="M16.3367 10.1133L14.8381 11.1783L14.5026 11.4174L13.9221 11.005L12.6583 10.1153L1.57031 17.9967H27.4359L16.3367 10.1133Z"
            fill="white"
          />
          <path
            d="M14.4985 9.19062L16.3286 7.88245L27.4237 -0.00390625H1.57031L14.4985 9.19062Z"
            fill="white"
          />
        </svg>
        <span class="footer-contacts__link-text">
          {{ _T("@Ask question") }}
        </span>
      </a>
      <p class="footer-contacts__caption">
        {{ _T("@Ask us any question you have") }}
      </p>
    </div>
  </div>
</template>

<script setup>
import FooterContactBlock from "~/modules/footer/components/FooterContactBlock.vue";

const hotline = {
  title: "@Hotline",
  phones: ["@Call center number"],
  ringo: true,
};

const contacts = [
  {
    title: "@Technical support",
    phones: ["@Technical Specialist Dmitriy tel.num"],
    schedules: [
      "@Technical support work schedule",
      "@Technical support work schedule weekends",
    ],
  },
  {
    title: "@Service center",
    phones: ["@Service phone"],
    schedules: [
      "@Service center working hours working days",
      "@Service center opening hours: weekends",
    ],
  },
  {
    title: "@Call-center",
    phones: ["@Call center number"],
    schedules: ["@Mon-Fri footer", "@Sat-Sun footer"],
    ringo: true,
  },
];
</script>

<style scoped lang="scss">
.footer-contacts {
  width: 100%;
  max-width: 230px;

  @include flex-container(column, stretch);
  gap: 16px;

  @include mobile {
    max-width: none;

    border-bottom: 1px solid var(--color-primary-base);

    padding-bottom: 16px;
  }

  &__block {
    @include flex-container(column, stretch);
    gap: 8px;
  }

  &__title {
    position: relative;
    @include font(20, 30, 600);
    text-transform: uppercase;
    color: var(--color-primary-base);
  }

  &__caption {
    @include font(12, 16);
    color: var(--color-sky-darker);
  }

  &__link {
    @include flex-container(row, flex-start, center);
    gap: 12px;

    &:hover .footer-contacts__link-text {
      color: var(--color-primary-base);
    }

    &:hover .footer-contacts__link-img path {
      fill: var(--color-primary-base);
    }
  }

  &__link-img path {
    transition: fill 0.2s;
  }

  &__link-text {
    @include font(16, 20);
    text-transform: uppercase;
    color: white;

    transition: color 0.2s;

    &.underline {
      text-decoration: underline;
    }
  }
}
</style>
