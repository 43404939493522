<template>
  <footer class="footer">
    <section class="footer__cont">
      <div class="content">
        <Logo
          class="content__logo-img-w"
          :orange="hoverLogo"
          @mouseenter="hoverLogo = true"
          @mouseleave="hoverLogo = false"
        />
        <FooterList :thin="true" :title="_T('@Products')" :list="categories">
          <MyLink class="content__catalog-link" name="catalog">
            {{ _T("@Go to catalog") }}
          </MyLink>
        </FooterList>
        <FooterList :title="_T('@Information')" :list="infoList" />
        <FooterContacts />
        <div class="content__copyrights">
          <Logo
            class="content__logo-img-w"
            :orange="hoverLogo"
            @mouseenter="hoverLogo = true"
            @mouseleave="hoverLogo = false"
          />
          <div class="content__copyrights-unit">
            <p class="content__caption">
              &#169; {{ _T("@All rights reserved") }}
            </p>
          </div>
          <div class="content__copyrights-unit">
            <div class="content__copyrights-img-w">
              <img
                class="content__copyrights-img"
                src="@/assets/icons/footerMasterCardIcon.svg"
                alt="MasterCard"
                loading="lazy"
              />
            </div>
            <div class="content__copyrights-img-w">
              <img
                class="content__copyrights-img"
                src="@/assets/icons/footerVisaIcon.svg"
                alt="Visa"
                loading="lazy"
              />
            </div>
          </div>
          <p v-if="_T('@footer entity') !== 'empty'" class="content__caption">
            {{ _T("@footer entity") }}
          </p>
        </div>
      </div>
      <div class="subscription__block">
        <div class="subscription__block-mailing">
          <div class="subscription__block-mailing-info">
            <h4 class="subscription__block-title">
              {{ _T("@Newsletter") }}
            </h4>
            <p class="subscription__block-text">
              {{ _T("@Subscribe to newsletter") }}
            </p>
          </div>
          <form class="subscription__block-mailing-form">
            <input
              v-model="emailValue"
              class="subscription__block-mailing-input"
              type="email"
              placeholder="E-mail"
            />
            <button
              class="subscription__block-mailing-btn"
              @click.prevent="subscribe"
            >
              {{ _T("@Subscribe") }}
            </button>
          </form>
        </div>
        <div class="subscription__block-social">
          <div class="subscription__block-social-w">
            <h4 class="subscription__block-title">
              {{ _T("@Join footer") }}
            </h4>
            <ul class="subscription__block-social-list">
              <li class="subscription__block-social-list-item">
                <a
                  :href="socials.facebook"
                  class="subscription__block-social-list-item-link"
                  target="_blank"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 2H14C12.6739 2 11.4021 2.52678 10.4645 3.46447C9.52678 4.40215 9 5.67392 9 7V10H6V14H9V22H13V14H16L17 10H13V7C13 6.73478 13.1054 6.48043 13.2929 6.29289C13.4804 6.10536 13.7348 6 14 6H17V2Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </li>
              <li class="subscription__block-social-list-item">
                <a
                  :href="socials.youtube"
                  class="subscription__block-social-list-item-link"
                  target="_blank"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 12L10.5 14V10L14 12Z"
                      fill="white"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 12.708V11.2929C2 8.39754 2 6.94988 2.90549 6.01841C3.81099 5.08694 5.23656 5.04662 8.08769 4.96598C9.43873 4.92777 10.8188 4.90039 12 4.90039C13.1812 4.90039 14.5613 4.92777 15.9123 4.96598C18.7634 5.04662 20.189 5.08694 21.0945 6.01841C22 6.94988 22 8.39754 22 11.2929V12.708C22 15.6033 22 17.0509 21.0945 17.9824C20.189 18.9139 18.7635 18.9542 15.9124 19.0349C14.5613 19.0731 13.1812 19.1005 12 19.1005C10.8188 19.1005 9.43867 19.0731 8.0876 19.0349C5.23651 18.9542 3.81097 18.9139 2.90548 17.9824C2 17.0509 2 15.6033 2 12.708Z"
                      stroke="white"
                      stroke-width="1.5"
                    />
                  </svg>
                </a>
              </li>
              <li class="subscription__block-social-list-item">
                <a
                  :href="socials.instagram"
                  class="subscription__block-social-list-item-link"
                  target="_blank"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16Z"
                      stroke="white"
                      stroke-width="1.5"
                    />
                    <path
                      d="M17.5 6.51L17.51 6.49889"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </li>
              <li class="subscription__block-social-list-item">
                <a
                  :href="socials.telegram"
                  class="subscription__block-social-list-item-link"
                  target="_blank"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 5L2 12.5L9 13.5M21 5L18.5 20L9 13.5M21 5L9 13.5M9 13.5V19L12.2488 15.7229"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <div class="content__copyrights-unit">
            <div class="content__copyrights-img-w">
              <img
                class="content__copyrights-img"
                src="@/assets/icons/footerMasterCardIcon.svg"
                alt="mastercard"
                loading="lazy"
              />
            </div>
            <div class="content__copyrights-img-w">
              <img
                class="content__copyrights-img"
                src="@/assets/icons/footerVisaIcon.svg"
                alt="visa"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script setup>
import FooterList from "~/modules/footer/components/FooterList.vue";
import { emailValidate, requiredValidate } from "~/utils/validators";
import Logo from "~~/modules/header/components/UI/Logo.vue";
import { useCategoriesStore } from "~~/store/categoriesStore";
import { useAPI } from "~/uses/useMyFetch";
import socials from "~~/utils/socials";
import useVuelidate from "@vuelidate/core";
import FooterContacts from "~/modules/footer/components/FooterContacts.vue";

const categoriesStore = useCategoriesStore();
const { getTopLevelCategories: categories } = storeToRefs(categoriesStore);

const infoList = [
  { id: 2, title: "@Contacts", routeName: "contacts" },
  { id: 3, title: "@Blog", routeName: "blog" },
  { id: 4, title: "@Delivery and payment", routeName: "delivery" },
  { id: 5, title: "@Warranty and Service", routeName: "warranty" },
  { id: 6, title: "@Privacy Policy", routeName: "privacy-policy" },
  { id: 7, title: "@Public offer agreement", routeName: "public-offer" },
  { id: 8, title: "@Cooperation", routeName: "partnership" },
  { id: 9, title: "@Services", routeName: "services" },
  { id: 10, title: "@Our objects", routeName: "installation-work" },
];

const hoverLogo = ref(false);
const emailValue = ref("");

const rules = computed(() => ({
  required: requiredValidate(),
  email: emailValidate(),
}));

const v = useVuelidate(rules, emailValue);

async function subscribe() {
  const result = await v.value.$validate();

  if (result) {
    useAPI("/marketing/event", {
      method: "POST",
      body: {
        event: "subscription",
        email: emailValue.value,
        isConfirmed: false,
      },
    }).then(() => {
      emailValue.value = "";
    });
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;

  @include flex-container(column, stretch, center);

  background-color: var(--color-grey-900);

  @extend %padding-wrp;

  &__cont {
    @extend %width-main;

    @include flex-container(column, stretch, center);
    gap: 48px;

    padding: 64px 0 40px;

    @include mobile {
      gap: 32px;

      padding: 48px 0;
    }
  }
}

.content {
  width: 100%;

  @include flex-container;
  gap: 24px 16px;

  & > .content__logo-img-w {
    display: none;

    @include mobile {
      display: block;
      margin-bottom: 16px;
    }
  }

  @include bigMobile {
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  @include mobile {
    flex-direction: column;
  }

  &__catalog-link {
    width: 100%;
    max-width: 200px;

    color: var(--color-primary-base);

    transition: color 0.2s ease;

    &:hover {
      color: var(--color-primary-light);
    }

    @include mobile {
      display: none;
    }
  }

  &__title {
    position: relative;
    @include font(20, 30, 600);
    text-transform: uppercase;
    color: var(--color-primary-base);
  }

  &__block-list-link {
    width: 100%;
    max-width: 200px;

    @include font;
    letter-spacing: 0.02em;
    color: white;

    transition: color 0.2s ease-in-out;

    @include mobile {
      max-width: none;
    }

    &:hover {
      color: var(--color-primary-light);
    }
  }

  &__caption {
    @include font(12, 16);
    letter-spacing: 0.02em;
    color: var(--color-sky-darker);
  }

  &__copyrights {
    width: 100%;
    max-width: 200px;

    @include flex-container(column, stretch, center);
    gap: 16px;

    @include mobile {
      display: none;
    }
  }

  &__logo-img-w {
    font-size: 0;

    @include mobile {
      display: none;
    }
  }

  &__copyrights-unit {
    @include flex-container(column, stretch, center);
    gap: 8px;

    & .content__caption {
      width: 100%;
      max-width: 120px;
      text-align: center;
    }

    & + & {
      @include flex-container;
      gap: 0;
    }
  }

  &__copyrights-caption {
    @include font(12, 16);
    letter-spacing: 0.02em;
    text-align: center;
    color: white;
  }
}

.subscription {
  &__block {
    width: 100%;

    @include flex-container(row, space-between, center);
    gap: 16px;

    border-radius: 16px;
    background-color: var(--color-sky-darkest);

    padding: 16px 32px;

    @include mobile {
      @include flex-container(column, center, flex-start);

      background: none;

      padding: 0;
    }
  }

  &__block-mailing {
    width: 100%;

    @include flex-container(row, flex-start, center);
    gap: 32px;

    @include bigMobile {
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  &__block-mailing-info {
    width: 100%;
    max-width: 355px;

    @include flex-container(column, stretch);
    gap: 16px;

    @include mobile {
      max-width: none;
      gap: 8px;
    }

    & .subscription__block-title {
      @include mobile {
        color: var(--color-primary-base);
      }
    }
  }

  &__block-title {
    @include font(20, 30, 600);
    text-transform: uppercase;
    color: white;
  }

  &__block-text {
    @include font(12, 16);
    letter-spacing: 0.02em;
    color: white;

    @include mobile {
      @include font(12, 16);
      color: var(--color-sky-lighter);
    }
  }

  &__block-mailing-form {
    width: 100%;
    max-width: 484px;

    @include flex-container(row, center, center);
  }

  &__block-mailing-input {
    width: 100%;
    max-height: 40px;

    border-radius: 6px 0 0 6px;

    background-color: white;

    padding: 12px 8px;

    &::placeholder {
      @include font(12, 16);
      letter-spacing: 0.02em;
      color: var(--color-ink-light);
    }
  }

  &__block-mailing-btn {
    position: relative;
    width: 100%;
    max-width: 183px;

    @include flex-container(row, center, center);

    @include font(18, 24);
    letter-spacing: 0.02em;
    color: white;

    border-radius: 6px;
    background-color: var(--color-primary-base);

    margin-left: -8px;
    padding: 8px 0;

    transition: color 0.2s ease-in-out;

    &:hover {
      background-color: var(--color-primary-light);
    }
  }

  &__block-social {
    @include mobile {
      width: 100%;
      @include flex-container(row, space-between, center);
      gap: 8px;
    }

    & .content__copyrights-unit {
      display: none;

      @include mobile {
        @include flex-container(column, center, center);
      }
    }
  }

  &__block-social-w {
    @include flex-container(column, stretch, center);
    gap: 16px;
  }

  &__block-social-list {
    @include flex-container(row, center, center);
    gap: 24px;
  }

  &__block-social-list-item-link {
    font-size: 0;
    display: inline-block;

    & svg {
      transition: transform 0.2s ease-in-out;
    }

    &:hover svg {
      transform: scale(1.1);
    }
  }
}
</style>
